<template>
	<transition
		enter-active-class="alertEnter animated fadeIn faster"
		leave-active-class="alertLeave animated fadeOut faster"
		mode="out-in"
	>
		<div
			v-if="showRaidAlert"
			class="alert-parent"
		>
			<!-- ALERT BG -->
			<div class="alert-bg"></div>

			<!-- ALERT TOP -->
			<transition
				enter-active-class="alertEnter animated slideInDown delay-2s"
				leave-active-class="alertLeave animated slideOutUp"
				mode="out-in"
			>
				<div
					v-if="showAlertTop"
					class="alert-top animated slideInDown delay-3s"
				>
					<div class="alert-top-bg"></div>
				</div>
			</transition>

			<!-- TEXT -->
			<div class="text">

				<!-- TEXT 1 -->
				<div class="text-1">
					{{ raiderName }}
				</div>

				<!-- TEXT 2 -->
				<div class="text-2">
					RAID
				</div>

				<!-- TEXT 3 -->
				<div class="text-3">
					<i class="fa fa-flash"></i> Warning:
					<ICountUp
						v-if="raiderCount && showRaidAlert"
						:delay="300"
						:endVal="raiderCount"
						class="text-primary"
				    />
					raiders imminent
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
	import { mapGetters } from 'vuex';
	import ICountUp from 'vue-countup-v2';

	export default {
		components: {
			ICountUp
		},
		props: {
			taskObject: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				showAlertTop: true,
				showRaidAlert: false, // there's a delay so the noise fires first
				options: {
		        	minSize: 500,
		        	maxSize: 12
		    	}
			}
		},
		computed: {
			...mapGetters({
				getViewerCount: 'twitch/getViewerCount'
			}),
			raiderName() {
				if (this.taskObject && this.taskObject.data.arg && this.taskObject.data.arg !== '<name>') {
					return this.taskObject.data.arg;
				} else {
					return 'Anonymous';
				}
			},
			raiderCount() {
				return parseInt(this.taskObject.data.raiders) || 666;
			}
		},
		mounted() {
			this.$playSound('nuclear-alarm-extended', { interrupt: true, volume: 1 }); // nuclear alarm

			// Pause the poller and update the lurker count right away, the poll will resume in 1.5 mins.
			this.$store.dispatch('twitch/pauseViewerCountPolling');

			// Let siren cook for a bit, before showing the alert.
			setTimeout(() => {

				// Pause the poller and update the lurker count right away, the poll will resume in 1.5 mins.
				// this.$store.dispatch('twitch/pauseViewerCountPolling');
				this.$store.dispatch('twitch/setViewerCount', this.getViewerCount + this.raiderCount);

				this.showRaidAlert = true;

				setTimeout(() => this.$playSound('siren-loop', { interrupt: true, volume: 0.3, loop: true }), 300); // nuclear alarm

				// // This could be handled by TV watcher.. maybe later?
				this.$store.dispatch('tasks/runTasks', [
					{ uuid: 'bb5398fa-660b-44e6-85d4-5720bf9879f4' }, // long static
					{ uuid: '541d6fd1-327f-485f-b8bd-757504aef24c', canceled: true }, // quit fullscreen
					// { uuid: 'cb3001c9-bc54-4700-b057-582e2cf594fa' } // remove any posters
					// { uuid: '156ccfdd-d055-4b7b-b695-c917641ddfde', canceled: false }, // hide posters
					// { uuid: 'c388392f-1931-4481-8c7e-99a0755b642a', canceled: false } // pause media poster
				]);

				this.$store.dispatch('tasks/runTasksRemotely', [
					{ uuid: '156ccfdd-d055-4b7b-b695-c917641ddfde', canceled: false }, // hide posters
					{ uuid: 'c388392f-1931-4481-8c7e-99a0755b642a', canceled: false } // pause media poster
				]);

				// Close the alert top before finishing.
				setTimeout(() => this.showAlertTop = false, 25000);

			}, 4500);
		},
		beforeDestroy() {

			console.log('RAID DESTROYED')

			// thinking with testing -- better to re-enable manually with raids
			// will this be chaotic??
			// this.$store.dispatch('tasks/runTasks', [
			// 	{ uuid: '156ccfdd-d055-4b7b-b695-c917641ddfde', canceled: true }, // hide posters
			// 	// { uuid: 'c388392f-1931-4481-8c7e-99a0755b642a', canceled: true } // pause media poster
			// ]);

			// this.$store.dispatch('tasks/runTasks', [
			// 	{ uuid: '85d7e282-f9a6-442e-9fe7-5d935499ea5e', data: { arg: 20 }} // volume - restore volume
			// ]);

			this.$stopSound('siren-loop');
		}
	}
</script>

<style lang="scss" scoped>

	.alert-parent {
		display: flex;
		align-items: center;
		// justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		padding: rem-calc(40 100 40 115);
		padding-bottom: rem-calc(220);
		z-index: 2;
	}

	//-----------------------------------------------------------------
	// ALERT BG
	//-----------------------------------------------------------------

	.alert-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#dc3545, 0.4);
		z-index: -2;

		animation: flash-bg 600ms infinite ease-in-out alternate;

		@keyframes flash-bg {
		    0%   { opacity: 0.2; }
		    100% { opacity: 1; }
		}
	}

	//-----------------------------------------------------------------
	// ALERT TOP
	//-----------------------------------------------------------------

	.alert-top {
	    position: absolute;
	    left: -46px;
	    right: 0;
	    top: 0;
	    height: rem-calc(35);

	    &:after {
	    	content: "";
	    	position: absolute;
	    	top: 0;
	    	width: rem-calc(140);
	    	height: 100%;
	    	left: calc(50% - #{rem-calc(100/2)});
	    	// background: darken($danger, 10%);
	    }
	}

	.alert-top .alert-top-bg {
		position: relative;
		left: -25px;
		width: 200%;
		height: 100%;
		background: $primary url('./assets/img/hazard-symbol-text@2x.png') repeat-x;
		background-size: rem-calc(160);
		will-change: transform;
		animation: flow-ltr 25s linear infinite; // 35 //steps(2)

		@keyframes flow-ltr {
			from { transform: translateX(0) }
			to   { transform: translateX(-320px) }
		}
	}

	//-----------------------------------------------------------------
	// TEXT
	//-----------------------------------------------------------------

	.alert-parent .text {
		position: relative;
		width: rem-calc(760);
		// border: 2px dashed black;
		font-family: 'Avenir Next Condensed';
		font-weight: 600;
		line-height: 0.9;
		text-transform: uppercase;
		color: #dc3545;
		text-shadow: 0 20px 60px rgba(black, 0.8); // 1
		transform: perspective(300px) rotateY(5deg) skew(0, 1deg);
		animation: float 3s infinite linear;

		@keyframes float {
			from { transform: rotate(0deg)   translate3d(4px, 0, 0) rotate(0deg) perspective(300px)    rotateY(5deg) skew(0, 1deg); }
			to   { transform: rotate(360deg) translate3d(4px, 0, 0) rotate(-360deg) perspective(300px) rotateY(5deg) skew(0, 1deg); }
		}
	}

	//-----------------------------------------------------------------
	// TEXT 1, 2, 3
	//-----------------------------------------------------------------

	.text-1 {
		font-size: rem-calc(70);
		color: white;
	}

	.text-2 {
		font-size: rem-calc(424); // hardcoded
		text-indent: -20px;
		color: $danger;
	    animation: flash-text 600ms infinite ease-in-out alternate;

	    @keyframes flash-text {
	        0%   { opacity: 0; }
	        100% { opacity: 1; }
	    }
	}

	.text-3 {
		font-size: rem-calc(45);
		color: rgba(white, 0.8);
	}

	//-----------------------------------------------------------------
	// BLOOD SPATTER
	//-----------------------------------------------------------------

	// .blood-spatter {
	// 	position: absolute;
	// 	top: rem-calc(-160);
	// 	left: rem-calc(-50);
	// 	width: rem-calc(600);
	// 	height: rem-calc(600);
	// 	// border: 1px dashed black;
	// 	animation-duration: 300ms;
	// 	animation-delay: 650ms;
	// 	z-index: -1;

	// 	.layer-1,
	// 	.layer-2 {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// }

	// .blood-spatter .layer-1 {
	// 	animation: blood-float-1 3s infinite linear;

	// 	@keyframes blood-float-1 {
	// 		from { transform: rotate(0deg)   translate3d(3px, 0, 0) rotate(0deg) }
	// 		to   { transform: rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg) }
	// 	}
	// }

	// .blood-spatter .layer-2 {
	// 	animation: blood-float-2 3s infinite linear;

	// 	@keyframes blood-float-2 {
	// 		from { transform: rotate(0deg)   translate3d(6px, 0, 0) rotate(0deg) }
	// 		to   { transform: rotate(360deg) translate3d(6px, 0, 0) rotate(-360deg) }
	// 	}
	// }

</style>